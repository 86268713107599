import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import "./LandingPage.css";
import { upcomingEvents, eventsWithCover } from './mainMenu/events/EventData';
import AuthContext from '../context/AuthProvider';
import MyDropdownMenu from './mainMenu/elementary/MyDropdownMenu';
import Logout from './mainMenu/users/Logout';
import InfiniteLooper from './forms/elementary/InfiniteLooper';

// fejecske, logout
import { CgProfile } from "react-icons/cg";
// dropdown
import { RiArrowDropDownLine } from "react-icons/ri";
// basic data edit
import { MdModeEdit } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";
// profile edit
import { GiMusicalNotes } from "react-icons/gi";
import Slideshow from './forms/elementary/Slideshow';

function LandingPage() {
    const { auth } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    // console.log(auth);

    const handleOpen = () => {
        setOpen(!open);
      };

    const handleClickOutside = () => {
        setOpen(false);
    };

    // console.log(`The value of the context: ${auth.userID}`);

    return (
        <div id="landing">
            <div className='landing-element' id="full-page">
                <div style={{paddingTop: '50px'}}>
                    <h3 style={{textAlign: "center", marginBottom:"10%"}}>
                        Üdvözöljük a Romániai Magyar Dalosszövetség weboldalán!
                    </h3>

                    <p style={{textAlign: 'center'}}>
                        A weboldal jelenleg beta üzemmódban van. <br/>
                        Az esetlegesen előforduló hibákat kérjük az&nbsp; 
                        <b> 
                            <a href="mailto: admin@dalosszovetseg.ro">
                                admin@dalosszovetseg.ro
                            </a>
                        </b> címen, <br/> képpel ellátott üzenetben jelezzék.
                    </p>

                    <h2>Eseményeink</h2>
{/* 
                    <InfiniteLooper direction="left" speed="25">
                        {
                            eventsWithCover.map((obj, index) => (
                                <Link to={"/esemenyek/" + obj.id}>
                                    <img key={index} src={obj.coverPhoto.img}/>
                                </Link>
                            ))
                        }
                    </InfiniteLooper> */}

                    <Slideshow toShow={eventsWithCover}/>

                    {/* {auth ? 
                        <>
                            <h3>Üdvözöljük, {auth.userLastName} {auth.userFirstName}!</h3>
                            {
                                (auth.roleName === "admin") ? null :
                                <>
                                    <Link to={"/felhasznalok/" + auth.userID + "/adatlap"}>Formáció adatlapjának módosítása</Link><br/>
                                    <Link to={"/felhasznalok/" + auth.userID + "/adatok"}>Adatok módosítása</Link>
                                </>
                            }
                        </> : null
                    } */}
                </div>


                {/* {
                    (upcomingEvents.length > 0) ?
                        <div id='landing-event-container' style={{paddingBottom: '50px'}}>
                            <h2>Közelgő események</h2>
                            <div className='upcoming-events-items'>
                                {
                                    upcomingEvents
                                }
                            </div>
                        </div> : null 
                } */}
            </div>
            <div className='landing-element' id='logo_container'>
                <a href="https://bgazrt.hu/" target="_blank" rel="noreferrer">
                    <img className='logo' src='img/logo/BGA_logo_2022.jpg'/>
                </a>
                <a href="https://www.mma.hu/" target="_blank" rel="noreferrer">
                    <img className='logo' src='img/logo/MMA.jpg'/>
                </a>
            </div>
        </div>
    );
}

export default LandingPage;